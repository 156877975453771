.headerSection {
    position: absolute;
    top: 0;
    z-index: 109;
    width: 100%;
    height: 76px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navBar {
    display: flex;
    list-style: none;
    gap: 10px;
    padding: 20px 60px 10px;

}

.navBar li {
    color: #dcdfe5;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    cursor: pointer;
    padding: 5px 8px;
}

.svgIcons {
    width: 20px;
    height: 20px;
    padding: 5px;
}

.socialIcons {}

.socialIcons ul {
    list-style: none;
    display: flex;
    gap: 10px;
    padding: 0 20px;
}

.socialIcons ul li {
    cursor: pointer;
}

.openIcons {
    display: none;
}

.modalOpen {
    position: fixed;
    background-color: #fff;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 1009;
}

.innerModal {
    padding: 20px;
    text-align: center;
}

.mobileNavBar {
    padding: 0;
}

.mobileNavBar li {
    cursor: pointer;
    list-style: none;
    padding: 10px 0;
    text-align: left;
    border-bottom: 1px solid #ccc;
}

.closeBtnSize {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.closeBtn {
    width: 20px;
    height: 20px;
    object-fit: contain;
}


@media only screen and (max-width:500px) {
    .headerSection {
        justify-content: flex-end;
    }

    .navBar {
        display: none;
    }

    .openIcons {
        display: block;
        width: 30px;
        height: 30px;
        object-fit: contain;
        background-color: #fff;
        border-radius: 4px;
        border: 1px solid #fff;
        cursor: pointer;
    }
}