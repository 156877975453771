.containerView {
    width: 100%;
    position: relative;
    text-align: center;
}

.Memories {
    max-width: 1440px;
    width: 80%;
    margin: 0 auto;
    padding: 72px 40px 96px 40px;
}

.Memories h2 {
    margin-bottom: 24px;
    line-height: 42px;
    font-size: 32px;
    font-family: var(--OpenSans-font);
    font-weight: 500;
}

.imageTrees {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.trees {
    width: 49%;
    height: 420px;
    object-fit: contain;
}

.formSection {
    width: 100%;
    margin: 0 auto;
    overflow: auto;
}

.formSection h3 {
    font-size: 16px;
    font-family: var(--OpenSans-font);
    color: #000;
    font-weight: 400;
    padding-bottom: 20px;
}

.divider {
    padding: 10px 0;
    display: block;
    margin: 20px auto 0;
    border-top: 2px solid #000;
}

/* Form Page styles */
.formPage {
    width: 95%;
    margin: 10px auto;
}

.bookingForm {
    width: 100%;
    margin: 30px auto;
    position: relative;
}

.bookingForm h2 {
    color: #1b1f29;
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 20px;
}

.buttonField {
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.buttonSubmit {
    background-color: #0083f3;
    color: #fff;
    width: 60%;
    margin: 10px 5px;
    padding: 11px 20px;
    font: 16px/21px var(--OpenSans-font);
    font-weight: 700;
    border: none;
    text-decoration: none;
    text-transform: none;
    border-radius: 5px;
    box-shadow: 2px 2px 4px -2px rgba(0, 0, 0, .4);
    cursor: pointer;
    height: auto;
    line-height: 1;
    letter-spacing: 1px;
}

.dateField {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.dateField label {
    display: block;
    font-size: 16px;
    color: #2a3163;
    line-height: 1.4;
}

.formControl {
    width: 100%;
    text-align: left;
    padding: 10px 0;
}

.inputDate {
    height: 100% !important;
    width: 100% !important;
    border: 1px solid #c7d4dd !important;
    border-radius: 6px !important;
    font-size: 16px !important;
    color: rgba(0, 0, 0, 1) !important;
    margin: 5px 0 0 0 !important;
    padding: 10px 8px 10px 8px !important;
    line-height: 1.5;
    box-sizing: border-box;
    cursor: default;
    font-family: sans-serif;
}

.selectForm {
    height: 100% !important;
    width: 100% !important;
    border-radius: 6px !important;
    font-size: 16px !important;
    color: #000000 !important;
    margin: 5px 0 0 0 !important;
    padding: 0 0 !important;
    line-height: 1.5;
    box-sizing: border-box;
    cursor: default;
    font-family: sans-serif;
    min-height: inherit;
}

.inputField {
    padding: 10px 0;
    text-align: left;
}

.inputField label {
    display: block;
    font-size: 16px;
    color: #2a3163;
    line-height: 1.4;
}

.inputForm {
    width: 100%;
    height: 100%;
    border-color: rgba(199, 212, 221, 1);
    border-radius: 6px 6px 6px 6px;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
    margin: 5px 0 0 0;
    padding: 10px 8px 10px 8px;
    line-height: 1.5;
    box-sizing: border-box;
    cursor: default;
    font-family: sans-serif;
    min-height: inherit;
}

.textareaForm {
    width: 100%;
    height: 100%;
    border-color: rgba(199, 212, 221, 1);
    border-radius: 6px 6px 6px 6px;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
    margin: 5px 0 0 0;
    padding: 10px 8px 10px 8px;
    line-height: 1.5;
    box-sizing: border-box;
    cursor: default;
    font-family: sans-serif;
    min-height: 100px;
}

.errorTxt {
    background: rgba(255, 0, 0, .122);
    border-radius: 7px;
    color: #860000;
    font-size: 13px;
    padding: 5px 10px;
    margin-top: 5px;
    font-family: var(--OpenSans-font);
    display: none;
}

/* ---------------  packages ---------------------*/
.packages {
    position: relative;
    text-align: left;
    width: 80%;
    margin: 20px auto 60px;
}
.packages h2 {
    color: #1b1f29;
    line-height: 32px;
    font-size: 32px;
    font-family: var(--OpenSans-font);
    font-weight: 500;
}
.packages p {
    font: 18px/26px 'Open Sans';
    margin: 15px 0;
    color: #4C5262;
    font-weight: 600;
}
.packagesPrice {
    padding-left: 20px;
}
.packagesPrice li {
    list-style-type: disc;
    color: #4c5262;
    font: 16px/26px Open Sans;
    margin: 0 0 10px;
}


@media only screen and (max-width: 1440px) {
    .trees {
        width: 40%;
        height: auto;
        object-fit: contain;
    }
}



@media only screen and (max-width: 768px) {
    .trees {
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    .Memories {
        padding: 32px 40px 46px 40px;
    }



}


@media only screen and (max-width:600px) {

    .dateField {
        display: flex;
        flex-wrap: wrap;
        gap: 0;
    }

    .buttonSubmit {
        width: 100%;
    }

    .Memories h2 {
        line-height: 32px;
        font-size: 24px;
    }
}