.sectionContainer {
    width: 85%;
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
    text-align: center;
    padding: 40px 0px;
}

.innerSection {
    padding: 30px 0;
    max-width: 1440px;
    width: 80%;
    margin: 0 auto;
}


.innerSection h2 {
    font-family: "Open Sans";
    font-size: 46px;
    font-weight: 500;
    color: #1b1f29;
    margin-bottom: 32px;
}

.innerSection h4 {
    font-family: "Open Sans";
    font-size: 24px;
    font-weight: 400;
    color: #1b1f29;
}

.cabinDetails {
    text-align: left;
}

.cabinDetails p {
    font: 16px/26px 'Open Sans';
    margin: 0 0 10px;
    color: #4C5262;
}

.cabinDetails h4 {
    font: 16px/26px 'Open Sans';
    margin: 20px 0 10px;
    color: #4C5262;
    font-weight: 600;
}

.cabinList {
    list-style-type: disc;
    padding: 0 20px 0;
}

.cabinList li {
    font: 16px/26px 'Open Sans';
    margin: 0;
    color: #4C5262;
    padding: 10px;
}

.CaroselSection {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 20px;
}

/* form Page Field bookingForm */
.bookingForm {
    width: 100%;
    margin: 30px auto;
    position: relative;
}

.bookingForm h2 {
    color: #1b1f29;
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 20px;
}

.buttonField {
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.buttonSubmit {
    background-color: #b2965f;
    color: #fff;
    width: 60%;
    margin: 10px 5px;
    padding: 11px 20px;
    font: 16px/21px var(--OpenSans-font);
    font-weight: 700;
    border: none;
    text-decoration: none;
    text-transform: none;
    border-radius: 5px;
    box-shadow: 2px 2px 4px -2px rgba(0, 0, 0, .4);
    cursor: pointer;
    height: auto;
    line-height: 1;
    letter-spacing: 1px;
}

.dateField {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.dateField label {
    display: block;
    font-size: 16px;
    color: #2a3163 !important;
    line-height: 1.4 !important;
}

.formControl {
    width: 100%;
    text-align: left;
    padding: 10px 0;
}

.inputDate {
    height: 100%;
    width: 100%;
    border-color: rgba(199, 212, 221, 1) !important;
    border-radius: 6px 6px 6px 6px !important;
    border-style: solid !important;
    border-width: 1px 1px 1px 1px !important;
    font-size: 16px !important;
    color: rgba(0, 0, 0, 1) !important;
    margin: 5px 0 0 0 !important;
    padding: 10px 8px 10px 8px !important;
    line-height: 1.5 !important;
    box-sizing: border-box;
    cursor: default;
    font-family: sans-serif;
    min-height: 45px;
}

.inputField {
    padding: 10px 0;
    text-align: left;
    padding: 10px 0;
}

.inputField label {
    display: block;
    font-size: 16px;
    color: #2a3163 !important;
    line-height: 1.4 !important;
}

.inputForm {
    width: 100%;
    height: 100%;
    border-color: rgba(199, 212, 221, 1) !important;
    border-radius: 6px 6px 6px 6px !important;
    border-style: solid !important;
    border-width: 1px 1px 1px 1px !important;
    font-size: 16px !important;
    color: rgba(0, 0, 0, 1) !important;
    margin: 5px 0 0 0 !important;
    padding: 10px 8px 10px 8px !important;
    line-height: 1.5 !important;
    box-sizing: border-box;
    cursor: default;
    font-family: sans-serif;
    min-height: inherit;
}

.textareaForm {
    width: 100%;
    height: 100%;
    border-color: rgba(199, 212, 221, 1) !important;
    border-radius: 6px 6px 6px 6px !important;
    border-style: solid !important;
    border-width: 1px 1px 1px 1px !important;
    font-size: 16px !important;
    color: rgba(0, 0, 0, 1) !important;
    margin: 5px 0 0 0 !important;
    padding: 10px 8px 10px 8px !important;
    line-height: 1.5 !important;
    box-sizing: border-box;
    cursor: default;
    font-family: sans-serif;
    min-height: 100px;
}

.errorTxt {
    background: rgba(255, 0, 0, .122);
    border-radius: 7px;
    color: #860000;
    font-size: 13px;
    padding: 5px 10px;
    margin-top: 5px;
    font-family: var(--OpenSans-font);
    display: none;
}


@media only screen and (max-width:600px) {
    .sectionContainer {
        padding: 20px 0;
    }

    .innerSection {
        padding: 0 0 30px;
        width: 100%;
    }

    .innerSection h2 {
        font-size: 28px;
        letter-spacing: -0.5px;
        margin-bottom: 20px;
    }

    .innerSection h4 {
        font-size: 18px;
    }

    .bookingForm h2 {
        font-size: 18px;
    }

    .dateField {
        display: flex;
        flex-wrap: wrap;
        gap: 0;
    }

    .buttonSubmit {
        width: 100%;
    }
}