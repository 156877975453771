
.dluTbn {
  margin: 0 !important;
}


@font-face {
  font-family: "Audrey Normal";
  src: url('./fonts/Audrey-Normal.otf') format('opentype');
}

@font-face {
  font-family: "Audrey Bold";
  src: url('./fonts/Audrey-Bold.otf') format('opentype');
}

@font-face {
  font-family: "Audrey Medium";
  src: url('./fonts/Audrey-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'DM Serif Display Italic';
  src: url('./fonts/DMSerifDisplay-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'DM Serif Display';
  src: url('./fonts/DMSerifDisplay-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "Open Sans";
  src: url('./fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "OpenSans Bold";
  src: url('./fonts/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: "OpenSans Medium";
  src: url('./fonts/OpenSans-Medium.ttf') format('truetype');
}

:root {
  /* Color */
  --body-color: #fafafa;
  --primary-color: #1671da;
  --danger-color: #e53a3a;
  --success-color: #57b14d;
  --border-dark: #e8e8e8;
  --border-medium: #e9e9e9;
  --border-light: #f3f3f3;
  --text-color: #131313;
  --text-light: #5b6064;
  --text-button: #1671da;
  --box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  --progress-bar-color: #f5a731;

  /* Font  */
  --primary-font: 'Audrey Normal';
  --OpenSans-font: 'Open Sans';
}

body {
  margin: 0;
  font-family: var(--OpenSans-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: var(--OpenSans-font);
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

h1,
h2 {
  font-weight: 700;
}

h3,
h4,
h5,
h6 {
  font-weight: 600;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

a,
a:link,
a:visited {
  color: var(--text-color);
  text-decoration: none;
}

input,
select,
textarea {
  color: #4e4e5a;
  border: none;
  outline: none;
}

input::placeholder {
  color: #b2b2b2;
}

textarea {
  resize: none;
}


input[type='radio'],
input[type='checkbox'] {
  display: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

label {
  cursor: inherit;
}

::-webkit-scrollbar {
  display: none;
}

.custom-scrollbar::-webkit-scrollbar {
  display: block;
  width: 6px;
  height: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #eee;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #1671da;
}

/* 
/-----------------------------------------/
UTITLITY CLASSES
/-----------------------------------------/
*/
.d-flex {
  display: flex;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-start;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-end {
  justify-content: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}

.g-6 {
  gap: 6px;
}

.g-8 {
  gap: 8px;
}

.g-12 {
  gap: 12px;
}

.g-16 {
  gap: 16px;
}

.g-24 {
  gap: 24px;
}

.g-32 {
  gap: 32px;
}

.G-g-6 {
  grid-gap: 6px;
}

.G-g-12 {
  grid-gap: 12px;
}

.G-g-16 {
  grid-gap: 16px;
}

.G-g-24 {
  grid-gap: 24px;
}

.G-g-32 {
  grid-gap: 32px;
}

/* Width */
.w-100 {
  width: 100% !important;
}

.w-75 {
  width: 75% !important;
}

.w-50 {
  width: 50% !important;
}

.w-25 {
  width: 25% !important;
}

.p-8 {
  padding: 8px;
}

.p-10 {
  padding: 10px;
}

.p-12 {
  padding: 12px;
}

.p-16 {
  padding: 16px;
}

.p-24 {
  padding: 24px;
}

.p-32 {
  padding: 32px;
}

.p-t-8 {
  padding-top: 8px;
}

.p-t-10 {
  padding-top: 10px;
}

.p-t-12 {
  padding-top: 12px;
}

.p-t-16 {
  padding-top: 16px;
}

.p-t-24 {
  padding-top: 24px;
}

.p-t-32 {
  padding-top: 32px;
}

.p-b-8 {
  padding-bottom: 8px;
}

.p-b-10 {
  padding-bottom: 10px;
}

.p-b-12 {
  padding-bottom: 12px;
}

.p-b-16 {
  padding-bottom: 16px;
}

.p-b-24 {
  padding-bottom: 24px;
}

.p-b-32 {
  padding-bottom: 32px;
}

.m-t-4 {
  margin-top: 4px;
}

.m-t-8 {
  margin-top: 8px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-12 {
  margin-top: 12px;
}

.m-t-14 {
  margin-top: 14px;
}

.m-t-16 {
  margin-top: 16px;
}

.m-t-17 {
  margin-top: 17px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-t-22 {
  margin-top: 22px;
}

.m-t-24 {
  margin-top: 24px;
}

.m-t-25 {
  margin-top: 25px;
}

.m-t-28 {
  margin-top: 28px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-t-32 {
  margin-top: 32px;
}

.m-t-35 {
  margin-top: 35px;
}

.m-t-36 {
  margin-top: 36px;
}

.m-t-38 {
  margin-top: 38px;
}

.m-t-40 {
  margin-top: 40px;
}

.m-t-42 {
  margin-top: 42px;
}

.m-t-48 {
  margin-top: 48px;
}

.m-b-4 {
  margin-bottom: 4px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-b-7 {
  margin-bottom: 7px;
}

.m-b-8 {
  margin-bottom: 8px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-12 {
  margin-bottom: 12px;
}

.m-b-16 {
  margin-bottom: 16px;
}

.m-b-17 {
  margin-bottom: 17px;
}

.m-b-18 {
  margin-bottom: 18px;
}

.m-b-22 {
  margin-bottom: 22px;
}

.m-b-24 {
  margin-bottom: 24px;
}

.m-b-25 {
  margin-bottom: 25px;
}

.m-b-26 {
  margin-bottom: 26px;
}

.m-b-27 {
  margin-bottom: 27px;
}

.m-b-28 {
  margin-bottom: 28px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-b-32 {
  margin-bottom: 32px;
}

.m-b-34 {
  margin-bottom: 34px;
}

.m-b-38 {
  margin-bottom: 38px;
}

.m-b-40 {
  margin-bottom: 40px;
}

.m-b-44 {
  margin-bottom: 44px;
}

.m-b-48 {
  margin-bottom: 48px;
}

/* MARGIN RIGHT */
.m-r-8 {
  margin-right: 8px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-12 {
  margin-right: 12px;
}

.m-r-16 {
  margin-right: 16px;
}

.m-r-24 {
  margin-right: 24px;
}

.m-r-32 {
  margin-right: 32px;
}

/* MARGIN LEFT */
.m-l-8 {
  margin-left: 8px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-l-12 {
  margin-left: 12px;
}

.m-l-16 {
  margin-left: 16px;
}

.m-l-24 {
  margin-left: 24px;
}

.m-l-32 {
  margin-left: 32px;
}

.c-pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-upper {
  text-transform: uppercase;
}

.text-lower {
  text-transform: lowercase;
}

.text-underline {
  text-decoration: underline;
}

.text-none {
  text-decoration: none;
}

.light {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.caption {
  color: var(--text-light);
  font-weight: 500;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-32 {
  font-size: 32px;
}

.danger {
  color: var(--danger-color);
}

/* .divider {
  border: none;
  border-top: 1px solid var(--border-medium);
  margin: 0;
} */

.hidden {
  display: none;
}

.visible {
  display: block;
}

.valign-top {
  vertical-align: top;
}

.valign-middle {
  vertical-align: middle;
}

.valign-bottom {
  vertical-align: bottom;
}


.btn,
a.btn {
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  transition: 0.2s;
}

.btn--small,
a.btn--small {
  padding: 14px 20px;
  font-size: 14px;
}

.btn--medium,
a.btn--medium {
  padding: 14px 24px;
  font-size: 17px;
}

.btn--large,
a.btn--large {
  padding: 18px 40px;
}

.btn--solid-primary,
a.btn--solid-primary {
  background-color: var(--primary-color);
  font-weight: 600;
  color: #fff;
}

.btn--solid-secondary,
a.btn--solid-secondary {
  background-color: #e5e5e5;
  color: #6c7784;
  font-weight: 600;
}

.btn--outline,
a.btn--outline {
  background-color: #fff;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.btn--edit,
.btn--text,
a.btn--text {
  color: var(--primary-color);
  cursor: pointer;
}

.btn--solid:hover,
a.btn--solid:hover {
  background-color: #fff;
  color: var(--text-color);
}

.btn--outline:hover,
a.btn--outline:hover {
  border-color: #fff;
}

.btn--counter {
  display: inline-flex;
  align-items: center;
  gap: 14px;
  padding: 2px 4px;
  border: 1px solid var(--border-dark);
  line-height: 1;
}

.btn--counter-minus,
.btn--counter-plus {
  cursor: pointer;
  font-size: 22px;
  color: var(--text-light);
  transform: translateY(-1px);
}

.btn .icon-caption p {
  font-weight: 600;
}

.card {
  border: 2px solid var(--border-light);
  border-radius: 12px;
  padding: 16px;
}

.card__grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
  grid-gap: 12px;
}

.card--solid {
  border-radius: 12px;
  padding: 16px;
  background-color: #f7f8fa;
}
