.containerSection {
    width: 80%;
    margin: 0 auto;
    position: relative;
    text-align: center;
}

.innerImage {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin: 20px auto;
}

.galleryView {
    width: 49%;
    height: 49%;
    object-fit: contain;
}

@media only screen and (max-width:650px) {
    .containerSection {
        width: 95%;
    }
}

@media only screen and (max-width:530px) {
    .galleryView {
        object-fit: contain;
        height: 100%;
        width: 100%;
    }
}