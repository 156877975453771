.footerContainer {
    width: 100%;
    height: auto;
    position: relative;
    background-color: #F1EFEE;
}

.innerContainer {
    padding: 50px 0;
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
}

.leftFooter {}

.crossBridge {
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
}


.leftFooter {
    text-align: center;
    padding-bottom: 10px;
}

.navFooter {
    padding: 15px 0;
}

.navFooter ul {
    list-style: none;
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 0;
}

.navFooter ul li {
    color: #B2965F;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
}

.navFooter ul li:hover {
    font-weight: 600;
    transition: 0s;
}

.contactButton {
    background-color: #B2965F;
    color: #fff;
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
    border: 1px solid #B2965F;
    text-transform: uppercase;
}

.contactButton:hover {
    background-color: #000000;
    color: #fff;
}

.rightFooter {
    text-align: center;
}

.socialIcon {}

.socialIcon ul {
    list-style: none;
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 0;
}

.svgIcons {
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.socialIcon ul li {
    padding: 5px;
    font-family: 'Open Sans', sans-serif;
}

.fbColor {
    background-color: #3b5998;
    border-radius: 100%;
    cursor: pointer;
    padding: 5px 6px 2px;
}

.instraColor {
    background-color: #000;
    border-radius: 100%;
    cursor: pointer;
    padding: 5px 6px 2px;
}

.footerContent {}

.footerContent h2 {
    color: #B2965F;
    font-size: 32px;
    line-height: 41px;
    font-family: 'Open Sans', sans-serif;
    margin: 0;
}

.footerContent p {
    color: #B2965F;
    font-size: 14px;
    line-height: 23px;
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 10px 0;
}

.copyRites {
    width: 100%;
    padding: 15px 0;
    text-align: center;
    background: #fff;
}

@media only screen and (max-width: 420px) {
    .footerContent h2 {
        font-size: 24px;
        line-height: 32px;
    }

    .crossBridge {
        margin-bottom: 20px;
        width: 100%;
    }
}