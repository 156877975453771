.contactContainer {
    background-color: #1b1f29;
    position: relative;
    width: 100%;
    text-align: center;
}

.InnerContainer {
    max-width: 1440px;
    width: 80%;
    margin: 0 auto;
    padding: 50px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.ourContact {
    width: 45%;
}

.ourContact h2 {
    color: #ffffff;
    font: 48px/48px "DM Serif Display";
    margin-bottom: 40px;

}

.dividedWhite {
    border-top: 4px solid #fff;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 20px;
}

.mapForm {
    padding: 0px 48px 20px 0px;
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: #4c5262;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    margin-bottom: 24px;
}

.contactDetails {
    width: 100%;
    text-align: left;
}

.mapForm h4 {
    color: #9599A5;
    font: 16px/32px var(--OpenSans-font);
    margin: 0 0 4px;
}

.mapForm p {
    font: 20px/32px var(--OpenSans-font);
    color: #ffffff;
}

.locationIcon {
    width: 20px;
    height: 20px;
    padding: 5px;
    border-radius: 50%;
    background-color: #FFFFFF0F;
}

.locationIcon path {
    fill: #fff;
}

.viewMap {
    padding: 30px 20px;
    width: 50%;
}

.mapAlignment {
    width: 100%;
    height: auto;
    padding: 50px 20px;
}

/* 1200 */
@media only screen and (max-width: 1200px) {
    .ourContact {
        width: 100%;
    }

    .viewMap {
        width: 100%;
    }

    .mapAlignment {
        padding: 10px 20px;
    }
}

@media only screen and (max-width: 768px) {
    .viewMap {
        padding: 0;
    }

    .mapAlignment iframe {
        width: 100%;
        height: auto;
        min-height: 450px;
    }
}


@media only screen and (max-width: 420px) {
    .mapAlignment iframe {
        width: 100%;
        height: auto;
        min-height: 350px;
    }
    .mapForm {
        flex-wrap: wrap;
        gap: 10px;
    }
    .mapAlignment {
        padding: 0;
    }
    .ourContact h2 {
        font: 38px/36px "DM Serif Display";
        margin-bottom: 30px;
    }
    .mapForm p {
        font: 16px/28px var(--OpenSans-font);
        color: #ffffff;
    }
}