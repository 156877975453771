.containerSection {
    width: 100%;
    max-width: 80%;
    margin: 20px auto;
    height: auto;
    position: relative;
    padding-top: 60px;
    padding-bottom: 220px;
}

.VenueSection {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.imageViews {
    width: 50%;
    position: relative;
    text-align: right;
}

.venueImg {
    max-width: 430px;
    width: 100%;
    height: auto;
    object-fit: contain;
    z-index: 9;
    box-shadow: -96px 96px 0px 0px #F6F6FE;
    position: relative;
    right: 0;
    top: 0;
}

.contentViews {
    width: 50%;
    padding: 0px 32px 0px 96px;
}

.contentViews h3 {
    color: #333;
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 32px;
    font-family: 'Open Sans', sans-serif;
}

.contentViews h2 {
    font-size: 47px;
    font-family: 'DM Serif Display', serif;
    font-weight: 300;
    color: #1b1f29;
    font-style: italic;
    line-height: 60px;
    margin: 0;
}

.contentViews p {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    color: #4C5262;
    line-height: 28px;
    letter-spacing: 1px;
    font-weight: 600;
}

.buttonField {
    padding: 24px 0px 0px 0px;
}

.btnClass {
    background-color: #B2965F;
    font-family: 'Open Sans', sans-serif;
    border: none;
    padding: 15px 10px;
    color: #fff;
    font-size: 15px;
}

.btnClass:hover {
    color: #fff;
    background-color: #000;
}

@media only screen and (max-width: 991px) {
    .VenueSection {
        display: flex;
        flex-wrap: wrap;
    }

    .venueImg {
        box-shadow: none;
        position: relative;
        top: 0;
        width: 100%;
    }

    .imageViews {
        position: relative;
        width: 100%;
        text-align: left;
    }

    .containerSection {
        padding-bottom: 60px;
    }

    .contentViews {
        padding: 20px 0;
        width: 100%;
    }
}

@media only screen and (max-width:600px) {

    .VenueSection {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    .contentViews h2 {
        font-size: 32px;
        line-height: 44px;
    }

    .contentViews p {
        font-size: 14px;
        line-height: 28px;
    }

    .imageViews {
        width: 100%;
    }

    .contentViews {
        width: 100%;
        padding: 0;
    }

    .venueImg {
        box-shadow: none;
        position: relative;
        top: 0;
        width: 100%;
    }

    .buttonField {
        text-align: center;
    }

    .containerSection {
        padding: 60px 0;
    }

}



@media only screen and (max-width:375px) {
    .contentViews h2 {
        font-size: 28px;
        line-height: 42px;
    }

}