.BannerCover {
    width: 100%;
    min-height: 350px;
    background-color: #B2965F;
}

.pageContainer {
    max-width: 1440px;
    padding: 128px 40px 64px 40px;
    margin: 0 auto;
}

.pageContainer h2 {
    text-align: center;
    font-size: 80px;
    line-height: 80px;
    color: #fff;
    font-family: var(--primary-font);
    padding-top: 60px;
    font-weight: 400;
}



@media only screen and (max-width: 768px) {
    .pageContainer h2 {
        text-align: center;
        font-size: 40px;
        line-height: 40px;
        color: #fff;
        font-family: var(--primary-font);
        padding-top: 100px;
        font-weight: 400;
    }
}


@media only screen and (max-width: 768px) {
    .pageContainer {
        padding: 90px 40px 50px 40px;
    }

    .BannerCover {
        min-height: 280px;
    }


}