.BannerSection {
    background-image: url('../../assets/png/banner.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.innerBanner {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #1b1f29;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    opacity: 0.5;
}

.centerAlignment {
    text-align: center;
    width: 100%;
    min-height: 800px;
    position: relative;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}

.crossBridgeImg {
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
    object-fit: contain;
}

.titleName {
    color: #FFFFFF;
    font-family: var(--primary-font);
    font-style: normal;
    font-size: 80px;
    line-height: 88px;
    font-weight: 300;
    margin: 20px auto;
    letter-spacing: -5px;
}

.paraName {
    text-align: center;
    color: #FFFFFF;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 20px;
    padding: 15px 20px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.buttonSection {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.bookButton {
    color: #FFFFFF;
    background-color: #B2965F;
    padding: 12px 15px;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 14px;
    border-radius: 0;
    border: none;
    text-transform: uppercase;
}

.bookButton:hover {
    transition: all .3s;
    background-color: #ffff;
    color: #000;
}


@media only screen and (max-width: 600px) {
    .titleName {
        font-size: 55px;
        line-height: 62px;
    }

    .paraName {
        max-width: 400px;
    }

    .buttonSection {
        flex-wrap: wrap;
        flex-direction: column;
        gap: 20px;
    }
}

@media only screen and (max-width: 500px) {
    .titleName {
        font-size: 42px;
        line-height: 52px;
    }

    .BannerSection {
        height: Calc(100vh - 60px);
    }
}

@media only screen and (max-width: 420px) {
    .buttonSection {
        flex-wrap: wrap;
        flex-direction: column;
        gap: 20px;
    }

    .BannerSection {
        height: 600px;
        flex-direction: column;
        gap: 20px;
    }

    .crossBridgeImg {
        width: 200px;
    }

    .titleName {
        font-size: 40px;
        line-height: 46px;
    }

    .paraName {
        max-width: 300px;
        padding: 0;
    }

    .centerAlignment {
        min-height: 600px;
    }

}